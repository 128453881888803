import { renderDatatable } from "../application"

columns = [
  { data: "email" },
  { data: "profession" },
  { data: "speciality" },
  {
    data: "profile",
    render: function(data, _) {
      if (data === 'completed') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-emerald-50 text-emerald-700 ring-emerald-700/10">Completed</span>`
      } else if (data === 'started') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-yellow-50 text-yellow-700 ring-yellow-700/10">Started</span>`
      } else {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/10">Not Started</span>`
      }
    },
    searchable: false,
    orderable: false
  },
  {
    data: "embedding",
    render: function(data, _) {
      if (data === 'true') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-emerald-50 text-emerald-700 ring-emerald-700/10">Yes</span>`
      } else {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/10">No</span>`
      }
    },
    searchable: false,
    orderable: false
  },
  { data: "matches" },
  { data: "created_at" },
  { data: "action", searchable: false, orderable: false }
]

order = [[6, "desc"]]

renderDatatable($('#admin-candidates-datatable'), columns, order)
