import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["input", "grouping"]
  static values = {
    turboFrame: String,
    url: String
  }

  connect() {
    this.fp = flatpickr(this.inputTarget, {
      mode: "range",
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      onChange: this.filterData.bind(this)
    })
  }

  filterData() {
    const dates = this.inputTarget.value.split(" to ")
    const groupBy = this.hasGroupingTarget ? this.groupingTarget.value : null
    const url = new URL(this.urlValue)

    if (dates.length === 2) {
      url.searchParams.set("start_date", dates[0])
      url.searchParams.set("end_date", dates[1])
    }

    if (groupBy) {
      url.searchParams.set("group_by", groupBy)
    }

    if (groupBy || dates.length === 2) {
      Turbo.visit(url, { frame: this.turboFrameValue })
    }
  }

  disconnect() {
    this.fp.destroy()
  }
}
